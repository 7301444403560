import { createSlice } from '@reduxjs/toolkit';

import {
  createLink,
  duplicateLink,
  editLink,
  getLink,
  fillLinkImportData,
  getLinkAttributeMapping,
  getRulesBaseStructure,
  getLinksList,
  importLinks,
} from '_api/links';

import cloneDeep from 'lodash/cloneDeep';
import { appendNewFieldsObj, pickAndRename } from 'utils/objectUtils';
import { XHR_STATUS } from 'constants/xhr-status';
import { transformAssignedAttributes } from './helpers';
import { BE_FE_LINKS_META } from './constants.js';
import { getSearchParams } from 'utils/urls.js';
import { fieldTypes } from 'layout/CommonLayout/CustomFormItem/constants';

const searchParams = getSearchParams();
const initialState = {
  linksList: [],
  linksListMeta: {
    currentPage: searchParams.page ? Number(searchParams.page) : 0,
    perPage: searchParams.count ? Number(searchParams.count) : 25,
  },
  linksListStatus: XHR_STATUS.idle,
  linkAttributesList: [],
  linkAttributesListStatus: XHR_STATUS.idle,
  // Get links
  getLinksLoading: false,
  getLinksSuccess: false,
  getLinksError: false,
  // Create link
  createLinkForm: {},
  createLinkLoading: false,
  createLinkSuccess: false,
  createLinkError: false,
  // Edit link
  editLink: {
    editLinkLoading: false,
    editLinkSuccess: false,
    editLinkError: false,
  },
  // Import links
  importLinks: {
    importLinksLoading: false,
    importLinksSuccess: false,
    importLinksError: false,
  },
  // Link attribute mapping
  linkAttributeMapping: {
    headerFirstRow: null,
    linkAttributeMappingList: [],
    linkAttributeInvalidHeadersFile: '',
    linkImportData: [],
    getLinkAttributeMappingLoading: false,
    getLinkAttributeMappingSuccess: false,
    getLinkAttributeMappingError: false,
  },
  // Active link during opened drawer
  activeLinkItem: {},
  // initial values for generate variants form
  initialValuesGenerateVariants: null,
  isOwnedLink: false,
  fillLinkImportData: {
    fillLinkImportDataLoading: false,
    fillLinkImportDataSuccess: false,
    fillLinkImportDataError: false,
  },
  // Generate Variants
  generateVariantsStatus: {
    generateVariantsLinkLoading: false,
    generateVariantsLinkSuccess: false,
    generateVariantsLinkError: '',
  },
  duplicateLink: {
    duplicateLinkLoading: false,
    duplicateLinkSuccess: false,
    duplicateLinkError: false,
  },
  snapshotTime: null,
};
const links = createSlice({
  name: 'links',
  initialState,
  reducers: {
    setLinksPage: (state, { payload }) => {
      state.linksListMeta.currentPage = payload;
    },
    setLinksPerPage: (state, { payload }) => {
      state.linksListMeta.perPage = payload;
    },
    resetCreateLinkData: state => ({
      ...state,
      createLinkLoading: false,
      createLinkSuccess: false,
      createLinkError: false,
    }),
    resetEditLinkData: state => ({
      ...state,
      editLink: {
        editLinkLoading: false,
        editLinkSuccess: false,
        editLinkError: false,
      },
    }),
    resetLinksListData: state => ({
      ...state,
      linksList: [],
      getLinksLoading: false,
      getLinksSuccess: false,
      getLinksError: false,
    }),
    resetActiveLink: state => ({
      ...state,
      activeLinkItem: {},
      assignedAttributesFormList: [],
      initialValuesGenerateVariants: null,
      isOwnedLink: false,
    }),
    resetLinkAttributeMappingData: state => ({
      ...state,
      linkAttributeMapping: {
        linkAttributeMappingList: [],
        linkAttributeInvalidHeadersFile: '',
        getLinkAttributeMappingLoading: false,
        getLinkAttributeMappingSuccess: false,
        getLinkAttributeMappingError: false,
      },
    }),
    resetFillLinkImportData: state => ({
      ...state,
      fillLinkImportData: {
        fillLinkImportDataLoading: false,
        fillLinkImportDataSuccess: false,
        fillLinkImportDataError: false,
      },
    }),
    updateLinkAttributeMapping: (state, action) => ({
      ...state,
      linkAttributeMapping: {
        ...state.linkAttributeMapping,
        ...action.payload,
      },
    }),
    resetDuplicateLinkData: state => ({
      ...state,
      duplicateLink: {
        duplicateLinkLoading: false,
        duplicateLinkSuccess: false,
        duplicateLinkError: false,
      },
    }),
  },
  extraReducers: {
    // Get links
    [getLinksList.pending]: state => ({
      ...state,
      linksList: [],
      getLinksLoading: true,
      getLinksSuccess: false,
      getLinksError: false,
      linksListStatus: XHR_STATUS.pending,
    }),
    [getLinksList.fulfilled]: (state, action) => {
      const { data: linksList = null, meta: linksListMeta = {} } = action.payload ?? {};

      const links = linksList.map(link => ({
        ...link,
        assigned_attributes: transformAssignedAttributes(link.assigned_attributes ?? []),
      }));

      const date = new Date().toISOString(); 

      return {
        ...state,
        linksListMeta: pickAndRename(linksListMeta, BE_FE_LINKS_META),
        linksList: links,
        getLinksLoading: false,
        getLinksSuccess: true,
        getLinksError: false,
        linksListStatus: XHR_STATUS.fulfilled,
        // mb better include this time to API response
        snapshotTime: `${date.split('.')[0]}+00:00`,
      };
    },
    [getLinksList.rejected]: state => ({
      ...state,
      getLinksLoading: false,
      getLinksSuccess: false,
      getLinksError: true,
      linksListStatus: XHR_STATUS.rejected,
    }),
    [getLink.pending]: (state) => {
      state.activeLinkItem.status = XHR_STATUS.pending;
    },
    [getLink.fulfilled]: (state, { payload: { data } }) => {
      const { assigned_attributes } = data;
      const assignedAttributeOptions = {};

      const attributeValues = assigned_attributes.reduce((memo, attribute) => {
        const option = { value: attribute.selected.key, label: attribute.selected.value };

        if (memo[attribute.code]) {
          if (Array.isArray(memo[attribute.code])) {
            memo[attribute.code].push(attribute.selected.key);
          } else {
            memo[attribute.code] = [memo[attribute.code], attribute.selected.key];
          }

          assignedAttributeOptions[attribute.code].push(option);
        } else {
          if (attribute.selected.key) {
            memo[attribute.code] = attribute.attribute_field_type === fieldTypes.CHECKBOX
              ? [attribute.selected.key]
              : attribute.selected.key;
            assignedAttributeOptions[attribute.code] = [option];
          } else {
            memo[attribute.code] = attribute.selected.value;
          }
        }

        return memo;
      }, {});

      // FIXME: next line is a hack need to delete later
      state.initialValuesGenerateVariants = Object
        .entries(attributeValues)
        .reduce((memo, [key, value]) => ({ ...memo, [key]: { value, options: [] } }), {});

      state.activeLinkItem = {
        ...data,
        assignedAttributeOptions,
        originalAssignedAttributes: assigned_attributes,
        assigned_attributes: attributeValues,
        status: XHR_STATUS.fulfilled,
      };
    },
    // Get Rules
    [getRulesBaseStructure.fulfilled]: (state, action) => {
      const { data: rulesList = [] } = action.payload ?? {};

      return {
        ...state,
        rulesList,
        getRulesBaseStructureLoading: false,
        getRulesBaseStructureLoadingSuccess: true,
        getRulesBaseStructureLoadingError: false,
      };
    },
    [getRulesBaseStructure.rejected]: state => ({
      ...state,
      getRulesBaseStructureLoading: false,
      getRulesBaseStructureSuccess: false,
      getRulesBaseStructureError: true,
    }),
    // Create link
    [createLink.fulfilled]: (state, action) => {
      const { data: newLinkData = {} } = action.payload ?? {};

      return {
        ...state,
        linksList: [...(state.linksList || []), newLinkData],
        createLinkLoading: false,
        createLinkSuccess: true,
        createLinkError: false,
      };
    },
    [createLink.rejected]: (state, action) => {
      const { message = '' } = action.payload;

      return {
        ...state,
        createLinkLoading: false,
        createLinkSuccess: false,
        createLinkError: message ?? 'Something went wrong while creating a link',
      };
    },
    // edit link
    [editLink.pending]: state => ({
      ...state,
      editLink: {
        editLinkLoading: true,
        editLinkSuccess: false,
        editLinkError: false,
      },
    }),
    [editLink.fulfilled]: state => ({
      ...state,
      editLink: {
        editLinkLoading: false,
        editLinkSuccess: true,
        editLinkError: false,
      },
    }),
    [editLink.rejected]: (state, action) => {
      const { message = '' } = action.payload;

      return {
        ...state,
        editLink: {
          editLinkLoading: false,
          editLinkSuccess: false,
          editLinkError: message ?? 'Something went wrong while editing a link',
        },
      };
    },
    // Import links
    [importLinks.pending]: state => ({
      ...state,
      importLinks: {
        importLinksLoading: true,
        importLinksSuccess: false,
        importLinksError: false,
      },
    }),
    [importLinks.fulfilled]: state => ({
      ...state,
      importLinks: {
        importLinksLoading: false,
        importLinksSuccess: true,
        importLinksError: false,
      },
    }),
    [importLinks.rejected]: state => ({
      ...state,
      importLinks: {
        importLinksLoading: false,
        importLinksSuccess: false,
        importLinksError: true,
      },
    }),
    // Get link attribute mapping list
    [getLinkAttributeMapping.pending]: state => ({
      ...state,
      linkAttributeMapping: {
        linkAttributeMappingList: [],
        getLinkAttributeMappingLoading: true,
        getLinkAttributeMappingSuccess: false,
        getLinkAttributeMappingError: false,
      },
    }),
    [getLinkAttributeMapping.fulfilled]: (state, action) => {
      const {
        attributeMapping: { data: linkAttributeMappingList = [] } = {},
        invalidHeaders: { data: { file_path: linkAttributeInvalidHeadersFile = '' } = {} } = {},
      } = action.payload ?? {};
      return {
        ...state,
        linkAttributeMapping: {
          linkAttributeMappingList,
          linkAttributeInvalidHeadersFile,
          getLinkAttributeMappingLoading: false,
          getLinkAttributeMappingSuccess: true,
          getLinkAttributeMappingError: false,
        },
      };
    },
    [getLinkAttributeMapping.rejected]: state => ({
      ...state,
      linkAttributeMapping: {
        getLinkAttributeMappingLoading: false,
        getLinkAttributeMappingSuccess: false,
        getLinkAttributeMappingError: true,
      },
    }),
    // Fill link import data
    [fillLinkImportData.pending]: state => ({
      ...state,
      fillLinkImportData: {
        fillLinkImportDataLoading: true,
        fillLinkImportDataSuccess: false,
        fillLinkImportDataError: false,
      },
    }),
    [fillLinkImportData.fulfilled]: state => ({
      ...state,
      fillLinkImportData: {
        fillLinkImportDataLoading: false,
        fillLinkImportDataSuccess: true,
        fillLinkImportDataError: false,
      },
    }),
    [fillLinkImportData.rejected]: state => ({
      ...state,
      fillLinkImportData: {
        fillLinkImportDataLoading: false,
        fillLinkImportDataSuccess: false,
        fillLinkImportDataError: true,
      },
    }),
    // Duplicate Link
    [duplicateLink.pending]: state => ({
      ...state,
      duplicateLink: {
        duplicateLinkLoading: true,
        duplicateLinkSuccess: false,
        duplicateLinkError: false,
      },
    }),
    [duplicateLink.fulfilled]: state => ({
      ...state,
      duplicateLink: {
        duplicateLinkLoading: false,
        duplicateLinkSuccess: true,
        duplicateLinkError: false,
      },
    }),
    [duplicateLink.rejected]: state => ({
      ...state,
      duplicateLink: {
        duplicateLinkLoading: false,
        duplicateLinkSuccess: false,
        duplicateLinkError: true,
      },
    }),
  },
});

export const {
  resetCreateLinkData,
  resetEditLinkData,
  resetLinksListData,
  resetLinkAttributeMappingData,
  resetFillLinkImportData,
  updateLinkAttributeMapping,
  resetDuplicateLinkData,
  setActiveLinkItem,
  resetActiveLink,
  setLinksPage,
  setLinksPerPage,
} = links.actions;

export default links.reducer;
